'use client'
import Image from "next/image";
import "./error.css";

import errorImg from "@/Helper/images/errorPage/error500.svg";
import CustomButton from "@/component/Buttons/CustomButton";
import Link from "next/link";

export default function GlobalError({ error, reset }) {
  return (
    <html>
      <body>
        <div className="error_container">
          <Image
            src={errorImg}
            width={585}
            height={321}
            alt="not-found"
          />
          <h1 className="error_heading">Oops! Page not found</h1>
          <p className="error_subheading">
            Sorry, the page you’re looking for doesn’t exist. If you think
            something is broken, report a problem.
          </p>
          <div className="error_btn_area">
            <Link href={"/"}>
              <CustomButton Text={"Back to home"} PrimaryColorBtn />
            </Link>
            <Link href={"/contact-us"}>
              <CustomButton Text={"Report a problem"} />
            </Link>
          </div>
        </div>
      </body>
    </html>
  );
}
